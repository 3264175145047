.App {}

.leaflet-popup-content-wrapper {
  border: 2px solid #283054;
  background-color: #FDF5FE;
}

.leaflet-popup-tip {
  background-color: #283054;
}
